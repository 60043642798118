/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');


$(function () {
    $('#emailLink').on('click', function (event) {
        event.preventDefault();
        var email = 'Henkel-Malerhotline@henkel.de';
        window.location = 'mailto:' + email;
    });
});

$(".epp-content-toggle").on('click', function(e) {
    $('.epp-cookie-content').slideUp();
    var target = $(this).parent().next('.epp-content-cookie-desc');
    target.slideToggle().toggleClass('epp-show');
    $('.epp-content-cookie-desc').not(target).slideUp().removeClass('epp-show'); // hide other open elements
    $('.epp-modal').click(function(event) {
        if (target.has(event.target).length == 0) {
            target.slideUp().removeClass('epp-show');
            target.find('.epp-cookie-content').slideUp();
            $('.epp-cookie-desc-toggle').removeClass('epp-toggle-up');
        }
    });
    e.stopPropagation();
});
$('.epp-detail-titel').on('click', function(e){
    var target = $(this).parent().parent();
    target.slideToggle().toggleClass('epp-show');
    $('.epp-content-cookie-desc').not(target).slideUp().removeClass('epp-show'); // hide other open elements
    $('.epp-modal').click(function(event) {
        if (target.has(event.target).length == 0) {
            target.slideUp().removeClass('epp-show');
            target.find('.epp-cookie-content').slideUp();
            $('.epp-cookie-desc-toggle').removeClass('epp-toggle-up');
        }
    });
    e.stopPropagation();

    $('#cookie-essentials').show();

    $('.cookie-desc-exit').on('click', function() {
        $('#cookie-essentials').hide();
    });
});
$(".js-cookie-consent-agree").on('click', function(){
    $("#cookie-disclaimer").remove();
    $('body').removeClass('epp-overflow');
    $('#wrapper').removeClass('epp-wrapper-blur');
});

$(document).ready(function () {

    $('#ifrm').contents().find('html').attr('style', 'height: 100%');
    $('#ifrm').contents().find('body').attr('style', 'height: 100%');

    if ($(document).find("#cookie-disclaimer").length > 0) {
        $('body').addClass('epp-overflow');
        $('#wrapper').addClass('epp-wrapper-blur');
    }

    $(window).scroll(function() {
        if ($(this).scrollTop() >= 50 && $(window).width() > 991) {        // If page is scrolled more than 50px or mobile page
            $('#return-to-top').fadeIn(200);    // Fade in the arrow
        } else {
            $('#return-to-top').fadeOut(200);   // Else fade out the arrow
        }
    });
    $('#return-to-top').click(function() {      // When arrow is clicked
        $('body,html').animate({
            scrollTop : 0                       // Scroll to top of body
        }, 500);
    });

    var owl = $('.owl-carousel:not(".owl-product-image")');
    owl.owlCarousel({
        margin: 10,
        items:1,
        loop:true,
        autoplay:true,
        autoplayTimeout:5000,
        autoplayHoverPause:true,
        nav: true,
        navText:["<div class='nav-btn prev-slide'><i class='fa fa-2x fa-chevron-left'></i></div>","<div class='nav-btn next-slide'><i class='fa fa-2x fa-chevron-right'></i></div>"],
    });
    $('.owl-product-image').owlCarousel({
        margin: 10,
        items:1,
        loop:true,
        autoplay:false,
        autoplayTimeout:2000,
        autoplayHoverPause:true,
        nav: true,
        navText:["<div class='nav-btn prev-slide'><i class='fa fa-2x fa-chevron-left'></i></div>","<div class='nav-btn next-slide'><i class='fa fa-2x fa-chevron-right'></i></div>"],
    });
    $("#owl-demo").owlCarousel({
        navigation : true,
        slideSpeed : 300,
        paginationSpeed : 500,
        items: 1,
        singleItem: true,
        autoPlay : false
    });

    var Sticky = require('sticky-js');

    var sticky = new Sticky('#sprung');


    $('a[data-scroll]').click(function(e) {
        e.preventDefault();
        //Set Offset Distance from top to account for fixed nav
        var offset = 10;
        var target = ( '#' + $(this).data('scroll') );
        var $target = $(target);
        //Animate the scroll to, include easing lib if you want more fancypants easings
        $('html, body').stop().animate({
            'scrollTop': $target.offset().top - offset
        }, 800, 'swing');
    });


    // hide mobile navbar collapse by clicking outside
    $(document).click(function (event) {
        var clickover = $(event.target);
        var navbar = $("#main-nav-content");
        var _opened = navbar.hasClass("show");
        if (_opened === true && (!clickover.hasClass("navbar-collapse") && !clickover.hasClass("nav-link-small") && !clickover.hasClass("nav-search") && !clickover.hasClass("fa-search") && !clickover.is("#language-select"))) {
            $("button.navbar-close").click();
        }
    });

    $('.download-clear').click(function (e) {
        $.each($("input[type='checkbox']:checked"), function(){
            $(this).prop('checked', false);
        });
        $('.button-unselect').addClass('inactive');
        $('.button-download-zip').addClass('inactive');
        $('#button-unselect').addClass('inactive');
        $('#button-email-zip').addClass('inactive');

        $('.parent-email-zip').addClass('inactive');
        $('.parent-download-zip').addClass('inactive');
        $('.parent-unselect').addClass('inactive');
    });

    $('input[type="checkbox"]').click(function(){

        var checked = $('input[type="checkbox"]:checked').length > 0;
        if (!checked){
            $('.button-unselect').addClass('inactive');
            $('.button-download-zip').addClass('inactive');
            $('#button-unselect').addClass('inactive');
            $('#button-email-zip').addClass('inactive');
            $('.parent-email-zip').addClass('inactive');
            $('.parent-download-zip').addClass('inactive');
            $('.parent-unselect').addClass('inactive');
        }else{
            $('.button-unselect').removeClass('inactive');
            $('.button-download-zip').removeClass('inactive');
            $('#button-unselect').removeClass('inactive');
            $('#button-email-zip').removeClass('inactive');
            $('.parent-email-zip').removeClass('inactive');
            $('.parent-download-zip').removeClass('inactive');
            $('.parent-unselect').removeClass('inactive');
        }
    });
    var textinputs = document.querySelectorAll('input[type=checkbox]');
    var empty = [].filter.call( textinputs, function( el ) {
        return !el.checked
    });

    if (textinputs.length == empty.length) {
        $('.button-unselect').addClass('inactive');
        $('.button-download-zip').addClass('inactive');
        $('#button-unselect').addClass('inactive');
        $('#button-email-zip').addClass('inactive');
        $('.parent-email-zip').addClass('inactive');
        $('.parent-download-zip').addClass('inactive');
        $('.parent-unselect').addClass('inactive');
        return false;
    }else{
        $('.button-unselect').removeClass('inactive');
        $('.button-download-zip').removeClass('inactive');
        $('#button-unselect').removeClass('inactive');
        $('#button-email-zip').removeClass('inactive');
        $('.parent-email-zip').removeClass('inactive');
        $('.parent-download-zip').removeClass('inactive');
        $('.parent-unselect').removeClass('inactive');
    }
});

$('input[name="zip"]').on('keydown', function(e) {
    if (this.selectionStart == 0 && e.which >= 65 && e.which <= 90) {
        this.setAttribute("maxlength", 7);
    }
});
$('input[name="zip"]').on('keyup', function(e) {
    if (/\D/g.test(this.value))
    {
        /*if (/[a-zA-Z]/.test(this.value.charAt(0))) {
            if (/[a-zA-Z]/.test(this.value.charAt(1))) {
                this.setAttribute("maxlength", 8);
                this.value = this.value.charAt(0).toUpperCase() + this.value.charAt(1).toUpperCase() + "-" + this.value.replace(/\D/g, '');
                return false;
            }
            this.setAttribute("maxlength", 8);
            if (this.value.charAt(0) == "D" || e.which == 68) {
                this.value = "DE" + "-" + this.value.replace(/\D/g, '');
            } else if (this.value.charAt(0) == "N" || e.which == 78) {
                this.value = "NL" + "-" + this.value.replace(/\D/g, '');
            } else if (this.value.charAt(0) == "L" || e.which == 76) {
                this.value = "LU" + "-" + this.value.replace(/\D/g, '');
            } else if (this.value.charAt(0) == "A" || e.which == 65) {
                this.value = "AT" + "-" + this.value.replace(/\D/g, '');
            } else {
                if (/[a-zA-Z]/.test(this.value.charAt(1))) {
                    this.setAttribute("maxlength", 8);
                    this.value = this.value.charAt(0).toUpperCase() + this.value.charAt(1).toUpperCase() + "-" + this.value.replace(/\D/g, '');
                }
                // this.value = this.value.charAt(0).toUpperCase();
                // this.value = this.value.charAt(0).toUpperCase() + "-" + this.value.replace(/\D/g, '');
            }
            if (/[0-9]/.test(this.value.charAt(1))){
                this.setAttribute("maxlength", 8);
                this.value = this.value.charAt(0).toUpperCase();
                return false;
            }
        } else {*/
            // Filter non-digits from input value.
            this.setAttribute("maxlength", 5);
            this.value = this.value.replace(/\D/g, '');
        // }
    } else
    {
        this.setAttribute("maxlength", 5);
    }
});

$( '#generate' ).click( function(e){
    $('#err1-container').parent().removeClass('mb-4');
    $('#err1-container').empty();
    $('#err2-container').empty();
    if ($('#product').val() === '') {
        $('#product').addClass( 'is-invalid' );
        $('<strong>', { html: "Bitte wählen Sie ein Produkt aus."  })
            .appendTo('#err1-container');
        $('#err1-container').parent().addClass('mb-4');
        e.preventDefault();
    } else {
        $('#product').removeClass( 'is-invalid' );
    }
    if ($('#plz').val().replace(/\s+/g, '').length <= 4 || /[a-zA-Z]/.test($('#plz').val().charAt(0)) && !/[a-zA-Z]/.test($('#plz').val().charAt(1))) {
        $('#plz').addClass( 'is-invalid' );
        $('<strong>', { html: "Bitte geben Sie eine gültige Postleitzahl ein."  })
            .appendTo('#err2-container');
        $('#err2-container').parent().addClass('mb-4')
        e.preventDefault();
    } else {
        $('#plz').removeClass( 'is-invalid' );
    }
});

$(document).on('click', '#more-haendler', function(){
    $( '#far-listen-container' ).show();
    $( this ).hide();
});
$(document).on('click', '#listen-container .liste', function(){
    // layer_markers.clearMarkers();
    // popupClear();
    popupHide();

    let latitude  = $( this ).attr( 'lat' );
    let longitude = $( this ).attr( 'lon' );
    let plz 	  = $( this ).attr( 'plz' );
    let distance  = $( this ).attr( 'km' );
    let name      = $( this ).attr( 'name' );
    let content   = $( this ).attr('content');

    /*var markers = [];
    markers.push({lon:parseFloat(longitude),lat:parseFloat(latitude),content:content});
    addMarker(layer_markers, markers[0]["lon"], markers[0]["lat"], markers[0]["content"]);*/

    /*var zoom = 14;
    if (distance > 5) {
        zoom = 13;
    }
    if (distance > 10) {
        zoom = 11;
    }
    if (distance > 20) {
        zoom = 10;
    }*/

    /*$( "#plz" ).val(plz);
    $( "#generate" ).trigger( "click" );*/

    /*$( "#latitude" ).val(latitude);
    $( "#longitude" ).val(longitude);*/

    // $( '#listen-container' ).empty();

    // jumpTo(parseFloat(longitude), parseFloat(latitude), 13);
    popupShow(content);
    $('body,html').animate({
        scrollTop : $('.container.main').offset().top                       // Scroll to top of body
    }, 500);
});

$(document).on('click', '#far-listen-container .liste', function(){
    popupHide();
    let latitude  = $( this ).attr( 'lat' );
    let longitude = $( this ).attr( 'lon' );
    let plz 	  = $( this ).attr( 'plz' );
    let content   = $( this ).attr('content');
    /*$( "#plz" ).val(plz);
    $( "#generate" ).trigger( "click" );*/
    jumpTo(parseFloat(longitude), parseFloat(latitude));
    popupShow(content);
});

$( '#map_reset' ).click( function(){
    layer_markers.clearMarkers();
    popupClear();
    $( '#plz' ).removeClass( 'is-invalid' );
    $( '#plz' ).removeAttr('value');
    $( '#product' ).removeClass( 'is-invalid' );
    $( '#product' ).find('option:selected').removeAttr('selected');
    $( '#product' ).find('option:first').attr('selected', 'selected');
    $( '#distance' ).find('option:selected').removeAttr('selected');
    $( '#distance' ).find('option:first').attr('selected', 'selected');
    $( '#plz-container' ).empty();
    $( '#listen-container' ).empty();
    $( '#far-listen-container' ).empty();
    $( '#err1-container' ).empty();
    $( '#err2-container' ).empty();
    addMarker(layer_markers,6.837240273060644, 51.17625427335292, "<div class=\"marker_flex pb-2\"><div class=\"marker_name align-self-center\"><h4 class=\"m-0\">Henkel</h4></div><div class=\"marker_brand_image\"><img src=\"../images/logo/henkel_logo.png\" style=\"height:2.1rem;\"></div></div><p>Henkelstraße 67,<br>40589 Düsseldorf</p>");
    jumpTo(6.837240273060644, 51.17625427335292, 12);
});

$("#map").on('mouseover', function disableScroll() {
    // Get the current page scroll position
    scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,

        // if any scroll is attempted, set this to the previous value
        window.onscroll = function() {
            window.scrollTo(scrollLeft, scrollTop);
        };
});
$(document).on('scroll', function() {
    if ($('#map:hover').length == 0) {
        window.onscroll = function () {};
    }
});

$('#email-download').on('click' , function(e) {
    if( !$("#download-email").val() )
    {
        !$("#download-email").addClass('is-invalid');
        e.preventDefault();
    }
});

$('.searchForm').on('submit', function(e){
    if(/^[\- :,=äöüß)(%&a-zA-Z0-9]*$/.test($(this).find('.nav-search').first().val())
        && $(this).find('.nav-search').first().val() !== ''
        && $(this).find('.nav-search').first().val() !== ' '
        && $(this).find('.nav-search').first().val() !== '.'
        && $(this).find('.nav-search').first().val() !== ','
        && $(this).find('.nav-search').first().val() !== '='
        && $(this).find('.nav-search').first().val() !== '-') {
        $(this).find('.nav-search').first().removeClass('is-invalid');
    } else {
        $(this).find('.nav-search').first().addClass('is-invalid');
        e.preventDefault();
    }
});

